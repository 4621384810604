 <template>
  <div>
    <CModal color="danger" :show="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Supplier</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ supplier ? supplier.first_name + " " + supplier.last_name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteSupplier(supplier)" v-else>
          Delete Supplier
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SupplierDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.suppliers.deleteModal,
      supplier: (state) => state.suppliers.deleteSupplier,
      isSaving: (state) => state.suppliers.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("suppliers/DELETE_MODAL", { bool: bool, supplier: null });
    },
    deleteSupplier(supplier) {
      this.$store.dispatch("suppliers/destroySupplier", {
        supplier: supplier
      });
    },
  },
};
</script>